import firebase from "firebase/app";
import "firebase/firestore";
import { db } from '@/firebase/config'
import { analytics } from '../../firebase/config';
const postNewOpp = async (alumniId, data) => {
  try {
    await db.collection("Opportunities").add({
      alumniId: alumniId,
      category: data.category,
      company: data.company,
      title: data.title,
      limit: data.limit,
      deadline: new firebase.firestore.Timestamp.fromDate(data.deadline).toDate(),
      description: data.description,
      appMilestone: 5
    })
    analytics.logEvent("new_opportunity_posted");
  } catch (error) {
    alert("postNewOpp: " + error)
  }
};

export default postNewOpp
